.App {
    margin: 0;
    padding: 20;
   }
   
   
   .css-r6ceg9 {
       margin: 0;
       align-items: flex-start !important;
       gap:0px;
       justify-content: space-between;
       width: 100%;
   }
   .MuiImageListItem-root {
       width: 100%;
   }
   .MuiImageListItem-root > img {
       height: 300px !important;
   }
   .css-a8q90b {
       max-width: 32%;
   }
   .css-isbt42{
       width: 100%;
       margin-left: 0;
   }
   .css-1qsxih2{
   padding: 0;
   }
   @media (max-width:992px){
       .css-a8q90b {
           max-width: 48%;
       }
   }
   @media (max-width:767px){
       .css-a8q90b {
           max-width: 100%;
           flex-basis: 100%;
       }
   }

   @media (max-width:800px){
        #contentDiv{
            height: 60vh !important;
            width: unset !important;
        }
   }

   .openseadragon-container{
        border: 1px solid #0f0d0d12 !important;
        border-radius: 7px !important;
        overflow: hidden !important;
        margin-top: 20px !important;
   }

   .openseadragon-container div:nth-child(2){
         display: none !important;
   }

   
   .css-1pakh3q {
       text-align: left !important;
   }
   .MuiImageListItem-root {color: #3f3f3f;}
   
   .MuiImageListItem-root p {
       overflow: hidden;
   }
   div#loader {
       background: #65656559 !important;
       height: 100vh;
       position: fixed;
       top: 0;
       width: 100%;
       display: flex;
       align-items: center;
       justify-content: center;
       z-index: 999;
   }
   .MuiImageListItem-root {
       color: #000000b5;
       font-weight: 700;
       text-decoration: none;
   }
   a.MuiButton-root {
       display: none;
   }
   
   
   .css-r6ceg9 {
       margin-left: 0 !important;
       align-items: flex-start !important;
       gap:0px;
       justify-content: space-between;
       width: 100%;
   }
   .MuiImageListItem-root {
       width: 100%;
   }
   .MuiImageListItem-root > img {
       height: 300px !important;
   }
   
   .css-1pakh3q {
       text-align: left !important;
       margin-top: 10px;
   }
   .MuiImageListItem-root {color: #3f3f3f;}
   
   .MuiImageListItem-root p {
       overflow: hidden;
       font-size: 14px;
       font-weight: 500;
       line-height: 1.4 !important;
   }
   div#loader {
       background: #65656559 !important;
       height: 100vh;
       position: fixed;
       top: 0;
       width: 100%;
       display: flex;
       align-items: center;
       justify-content: center;
       z-index: 999;
   }
   .MuiImageListItem-root {
       color: #000000b5;
       font-weight: 700;
       text-decoration: none;
   }
   a.MuiButton-root {
       display: none;
   }
   
   w {}
   
   .css-1orzy9s {
       width: 100%;
       margin-bottom: 40px;
   }
   
   .css-1hut38s .MuiInputBase-input {
       width: 100%;
       border: 1px solid beige;
   }
   
   .MuiInputBase-root.MuiInputBase-colorPrimary.css-1hut38s {
       width: 100%;
   }
   
   .css-191lty2 {
       padding-left: 0;
   }
   
   .css-29hkq-MuiGrid-root {
       flex-grow: 1;
       display: flex;
       align-items: stretch;
       border-radius: 4px;
       margin-bottom: 20px;
       box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
   }
   .css-fgqrxg-MuiGrid-root {
       align-items: flex-start !important;
       align-items: stretch !important;
       justify-content: space-between;
   }
   .css-7rtvwf-MuiImageListItem-root .MuiImageListItem-img {
       object-fit: unset;
       width: 100%;
       height: auto !important;
       margin-bottom: 20px;
   }
   .MuiImageListItem-root p{
       text-align: center;
   }
   .css-79elbk {
       position: relative;
       margin: auto;
       border: 1px solid #000;
   }
   .css-bhp9pd-MuiPaper-root-MuiCard-root{
       border-radius:0 !important;
       box-shadow:none !important;
   }
   .css-knke7w-MuiContainer-root{
       padding: 64px 16px 48px;
   }
   .css-1oqqzyl-MuiContainer-root{
       padding: 0 !important;
   }
   .css-knke7w-MuiContainer-root{
       padding: 0 !important;
   }
   .css-vzr58e-MuiContainer-root{
       padding: 0 !important;
   }
   .css-fgqrxg-MuiGrid-root{
       width: 100%;
       margin: 0;
   }
   main.MuiContainer-root.MuiContainer-maxWidthLg.MuiContainer-disableGutters.css-knke7w-MuiContainer-root {
       padding: 0 20px !important;
   }
   main.MuiContainer-root.MuiContainer-maxWidthLg.css-1oqqzyl-MuiContainer-root {
       padding: 0 20px !important;
   }
   .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.css-mhc70k-MuiGrid-root {
       padding: 40px 0 40px;
   }
   .css-fgqrxg-MuiGrid-root{
       width: 100% !important;
       margin-left: 0 !important;
   }
   @media (min-width: 1200px){
   .css-29hkq-MuiGrid-root {
       flex-basis: 32% !important;
   }
   }
   @media (max-width: 1200px){
       .css-29hkq-MuiGrid-root {
           max-width: 32.5% !important;
           flex-basis:32% !important;
       }
   }
   @media (max-width: 900px){
       .css-29hkq-MuiGrid-root {
           max-width: 48% !important;
           flex-basis:48% !important;
       } 
   }
   @media (max-width: 600px){
       .css-29hkq-MuiGrid-root {
           max-width: 100% !important;
           flex-basis:100% !important;
       }
   }
   
   .transform-component-module_content__2jYgh {
       width: -moz-fit-content;
       width: unset !important;
   }
   
   .transform-component-module_wrapper__1_Fgj {
       width: 100% !important;
   }

   #Title{
    font-size: 30px;
    font-weight: 700;
   }


   /* csss for boootstap */
   .card_1{
    box-shadow: 2px 5px 10px rgb(0 0 0 / 50%);
    padding: 15px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
   }
   h2{
    font-weight: 600;
    margin-top: 10px;
    font-size: 24px;
   }
   .transform-component-module_wrapper__1_Fgj{
    width: 100% !important;
   }

   a.card_1 {
    text-decoration: none;
    color: #333;
}
   
#contentDiv{
margin: auto;
}
div#ImageData{
    text-align: center;
    padding: 20px 0;
}
div#icons {
    padding: 16px;
    text-align: center;
  }